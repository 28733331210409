import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const Logo = ({ src, position, padding }) => {
  const theme = useTheme();
  const isScreenSizeOver900 = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: isScreenSizeOver900 ? position : "flex-start",
        alignItems: "center",
        paddingLeft: isScreenSizeOver900 ? padding : "2em",
      }}
    >
      <img
        src={src}
        style={{
          width: isScreenSizeOver900 ? "4.5em" : "4em",
          height: isScreenSizeOver900 ? "4em" : "3.5em",
        }}
        alt="Logo DSC Technology"
      />
    </Box>
  );
};

export default Logo;
