import panasonic from "../assets/techno/panasonic.png";
import alcatel from "../assets/techno/alcatel.png";
import cisco from "../assets/techno/cisco.png";
import dlink from "../assets/techno/dlink.png";
import legrand from "../assets/techno/legrand.png";
import telesystem from "../assets/techno/telesystem.png";
import tplink from "../assets/techno/tplink.png";
import schneiderElectric from "../assets/techno/schneiderElectric.png";
import hager from "../assets/techno/hager.png";
import zkteco from "../assets/techno/zkteco.png";
import hikvision from "../assets/techno/hikvision.png";
import dahuatechnology from "../assets/techno/dahuatechnology.png";
import apollo from "../assets/techno/apollo.png";
import honeywell from "../assets/techno/honeywell.png";
import siemens from "../assets/techno/siemens.png";
import genetecSecurityCenter from "../assets/techno/genetecSecurityCenter.png";
import teletek from "../assets/techno/teletek.png";
import fortinet from "../assets/techno/fortinet.png";
import ruckus from "../assets/techno/ruckus.png";
import sophos from "../assets/techno/sophos.png";
import veritas from "../assets/techno/veritas.png";
import vmware from "../assets/techno/vmware.png";
import veeam from "../assets/techno/veeam.png";
import bitdefender from "../assets/techno/bitdefender.png";
import mongodb from "../assets/techno/mongodb.png";
import expressjs from "../assets/techno/expressjs.png";
import react from "../assets/techno/react.png";
import nodejs from "../assets/techno/nodejs.png";
import mysql from "../assets/techno/mysql.png";
import wordpress from "../assets/techno/wordpress.png";
import odoo from "../assets/techno/odoo.png";

export const dispatcher = (dispatch, SET, query, value, key) => {
  return dispatch({
    type: SET,
    payload: {
      [query]: {
        ...query,
        [key]: value,
      },
    },
  });
};
//TYPING FUNCTION
export const TypingEffect = (
  current,
  phrase,
  phrases,
  phraseIndex,
  timer,
  setCurrentPhrase,
  setPhraseIndex
) => {
  if (current !== phrase) {
    timer = setTimeout(() => {
      setCurrentPhrase(phrase.slice(0, current.length + 1));
    }, 200);
  } else if (phraseIndex === phrases.length - 1) {
    setTimeout(() => {
      setPhraseIndex(0);
      setCurrentPhrase("");
    }, 3000);
  } else {
    setTimeout(() => {
      setPhraseIndex(phraseIndex + 1);
      setCurrentPhrase("");
    }, 3000);
  }

  return () => clearTimeout(timer);
};
export const Interval = (setCursorVisible) => {
  const cursorInterval = setInterval(() => {
    setCursorVisible((prevState) => !prevState);
  }, 300);
  return () => clearInterval(cursorInterval);
};
//LANGUE SELECTOR FUNCTION
export const HandleToggle = (setOpen, prevOpen) => {
  setOpen((prevOpen) => !prevOpen);
};
//LOGOUT USER
export const handleLogout = (navigate, dispatch) => {
  localStorage.removeItem("auth");
  localStorage.removeItem("id");
  localStorage.removeItem("user_name");
  localStorage.removeItem("role");
  return navigate("/sign-in");
};
export const HandleClose = (e, anchorRef, setOpen, popperRef) => {
  if (
    anchorRef.current &&
    anchorRef.current.contains(e.target) &&
    popperRef.current &&
    popperRef.current.contains(e.target)
  ) {
    return;
  }

  setOpen(false);
};
export const HandleCloseMenu = (setOpen) => {
  setOpen(false);
};
export const HandleListKeyDown = (e, setOpen) => {
  if (e.key === "Tab") {
    e.preventDefault();
    setOpen(false);
  } else if (e.key === "Escape") {
    setOpen(false);
  }
};
export const Focus = (open, prevOpen, anchorRef) => {
  if (prevOpen.current === true && open === false) {
    anchorRef.current.focus();
  }

  prevOpen.current = open;
};

export const detailContent = [
  [
    [
      "Aménagement des salles it y compri les armoirs informatiques et faux plancher",
      "L'aménagement des salles, y compris les armoires informatiques et le faux plancher, consiste à concevoir et installer un environnement physique adéquat pour les équipements informatiques et de communication d'une entreprise ou d'une organisation. Les salles sont généralement équipées d'armoires pour stocker les serveurs, les routeurs, les commutateurs et les autres équipements de réseau, tandis que le faux plancher permet de dissimuler les câbles et de fournir une ventilation adéquate pour les équipements. Un aménagement efficace peut contribuer à optimiser les performances et la fiabilité des systèmes informatiques, tout en facilitant la maintenance et l'extension future des équipements.",
    ],
    [
      "Installation du réseau informatique et téléphonique",
      "Installation du réseau informatique et téléphonique : Nous proposons l'installation de réseaux informatiques et téléphoniques pour votre entreprise ou organisation, ce qui permet de faciliter la communication et le partage de données entre les différents utilisateurs.",
    ],
    [
      "Installation et configuration des points d'accès Switching et routing",
      "Installation et configuration des points d'accès Switching et routing : Nous installons et configurons des équipements de réseau tels que les points d'accès, les commutateurs et les routeurs pour assurer une connectivité et une performance optimale du réseau.",
    ],
    [
      "Installation et soudure fibre optique",
      "Installation et soudure fibre optique : Nous réalisons l'installation de la fibre optique pour assurer une connectivité à haut débit et une transmission des données fiable.",
    ],
    [
      "Certification des prises RJ 45 toute catégories",
      "Certification des prises RJ 45 toute catégories : Nous effectuons la certification des prises RJ45 pour garantir la qualité et la performance des connexions.",
    ],
  ],

  [
    [
      "Vidéosurveillance (IP, Analaogique)",
      "Vidéosurveillance (IP, Analaogique) : Nous installons des systèmes de vidéosurveillance pour surveiller votre entreprise, ce qui peut aider à prévenir les actes de vandalisme et de vol.",
    ],
    [
      "Contrôle d’accès et Anti-intrusion.",
      "Contrôle d’accès et Anti-intrusion : Nous installons des systèmes de contrôle d'accès et d'anti-intrusion pour sécuriser votre entreprise contre les intrusions non autorisées.",
    ],
    [
      "Protection du périmètre.",
      "Protection du périmètre : Nous proposons des solutions pour protéger le périmètre de votre entreprise contre les intrusions, telles que les clôtures, les barrières et les systèmes de détection.",
    ],
    [
      "Détection incendie.",
      "Détection incendie : Nous installons des systèmes de détection incendie pour garantir la sécurité de votre entreprise en cas d'incendie.",
    ],
    [
      "Solution de Sécurité unifiée sur infrastructure IP.",
      "Solution de Sécurité unifiée sur infrastructure IP : Nous proposons des solutions de sécurité unifiées pour protéger votre entreprise contre les menaces en ligne à travers l'infrastructure IP.",
    ],
    [
      "Electricite Batiment",
      "Electricité Bâtiment : Nous proposons des services d'installation électrique pour votre entreprise, y compris les réseaux électriques, l'éclairage et les prises électriques.",
    ],
    [
      "Installation des systémes ondules et non ondulés",
      "Installation des systèmes ondulés et non ondulés : Nous installons des systèmes d'alimentation électrique de secours, tels que des onduleurs et des groupes électrogènes, pour garantir une alimentation électrique ininterrompue.",
    ],
    [
      "Installation des armoires électriques générales et industrielles",
      "Installation des armoires électriques générales et industrielles : Nous installons des armoires électriques pour protéger les équipements électriques de votre entreprise.",
    ],
    [
      "Installation des groupes électrogènes",
      "Installation des groupes électrogènes : Nous installons des groupes électrogènes pour garantir une alimentation électrique de secours en cas de panne de courant.",
    ],
    [
      "Eclairage et lumière",
      "Éclairage et lumière : Nous proposons des solutions d'éclairage pour votre entreprise, y compris l'éclairage extérieur et intérieur.",
    ],
    [
      "Installation des climatiseurs",
      "Installation des climatiseurs : Nous installons des systèmes de climatisation pour assurer un environnement",
    ],
  ],
  [
    [
      "Solution de securité reseaux et firewalling",
      "Solution de sécurité réseaux et firewalling : Nous proposons des solutions de sécurité pour protéger votre réseau et vos données contre les attaques externes et internes, grâce à des pare-feux et des politiques de sécurité.",
    ],
    [
      "Antivirus et antispam",
      "Antivirus et antispam : Nous proposons des solutions antivirus et antispam pour protéger votre entreprise contre les menaces en ligne telles que les virus, les logiciels malveillants et les spams.",
    ],
  ],
  [
    [
      "Logiciels de gestion des entreprises : stock, production, facturation ect",
      "Simplifiez la gestion de votre entreprise avec nos solutions sur mesure, optimisées pour améliorer votre productivité et vous aider à prendre des décisions éclairées.",
    ],
    [
      "Création de sites vitrine",
      "Notre objectif est de donner à votre entreprise une présence en ligne percutante, en mettant en avant vos services, votre expertise et vos valeurs. ",
    ],
    [
      "Création d’application web et mobile.",
      "Nous concevons des solutions sur mesure pour répondre à vos besoins spécifiques, offrant une expérience utilisateur exceptionnelle et des fonctionnalités avancées.",
    ],
  ],
];
export const detailDisplayer = (service) => {
  const services = {
    0: detailContent[0],
    1: detailContent[1],
    2: detailContent[2],
    3: detailContent[3],
  };
  return services[service];
};

const technoContent = [
  [panasonic, alcatel, cisco, dlink, legrand, telesystem, tplink],
  [
    legrand,
    schneiderElectric,
    hager,
    zkteco,
    hikvision,
    dahuatechnology,
    apollo,
    honeywell,
    siemens,
    genetecSecurityCenter,
    teletek,
  ],
  [fortinet, ruckus, sophos, veritas, vmware, bitdefender, veeam],
  [mongodb, expressjs, react, nodejs, mysql, wordpress, odoo],
];
export const technoDisplayer = (service) => {
  const services = {
    0: technoContent[0],
    1: technoContent[1],
    2: technoContent[2],
    3: technoContent[3],
  };
  return services[service];
};
export const detailSelector = (chip) => {
  const details = {
    "Réseaux & téléphonie": 0,
    Courant: 1,
    Sécurite: 2,
    Développement: 3,
  };
  return details[chip];
};

export const data = [
  {
    chip: "Réseaux & téléphonie",
    title: "Infrastructures IT & téléphonie",
    discription:
      "Nous sommes spécialisés dans l'installation de systèmes de communication et d'échange d'informations au sein des entreprises.",
  },
  {
    chip: "Courant",
    title: "Courant faible et courant fort",
    discription:
      "Nous proposons des solutions répondant à vos besoins en matière de vidéosurveillance, de contrôle d'accès et de réseaux basse et haute tension.",
  },
  {
    chip: "Sécurite",
    title: "Securite informatique",
    discription:
      "Nous mettons à disposition de nos clients des solutions de sécurité pour leurs réseaux, systèmes et données.",
  },
  {
    chip: "Développement",
    title: "Développement informatiques",
    discription:
      "Nous développons des solutions informatiques personnalisées pour répondre aux besoins spécifiques de nos clients.",
  },
];
