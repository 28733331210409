import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { Card, useTheme } from "@mui/material";

function Map() {
  const theme = useTheme();
  useEffect(() => {
    const map = L.map("map").setView([36.64, 3.04], 11);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 50,
    }).addTo(map);

    const icon = L.icon({
      iconUrl: markerIcon,
      shadowUrl: markerShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });

    L.marker([36.64, 3.04], { icon: icon }).addTo(map);

    return () => {
      map.remove();
    };
  }, []);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.firstBox.main,
        color: theme.palette.text.thirdly,
        width: { xs: "100%", md: "100%" },
        height: { xs: "25em", md: "100%" },
        backgroundBlendMode: "initial",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "hidden",
        transition: ".2s ease-in-out",
        zIndex: "1",
      }}
    >
      <div id="map" style={{ height: "100%" }}></div>
    </Card>
  );
}

export default Map;
