import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const users = [
  {
    event_name: "event 1",
    begin: "01/01/2023",
    end: "05/01/2023",
  },
  {
    event_name: "event 2",
    begin: "01/01/2024",
    end: "05/01/2024",
  },
  {
    event_name: "event 3",
    begin: "01/01/2024",
    end: "05/01/2024",
  },
];

const EventList = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nom de l'evenement</StyledTableCell>
            <StyledTableCell align="right">Date de début</StyledTableCell>
            <StyledTableCell align="right">Date de fin</StyledTableCell>
            <StyledTableCell align="right">Modifier</StyledTableCell>
            <StyledTableCell align="right">Supprimer</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell component="th" scope="row">
                {user.event_name}
              </StyledTableCell>
              <StyledTableCell align="right">{user.begin}</StyledTableCell>
              <StyledTableCell align="right">{user.end}</StyledTableCell>
              <StyledTableCell align="right">
                <ModeEditIcon />
              </StyledTableCell>
              <StyledTableCell align="right">
                <DeleteForeverIcon />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default EventList;
