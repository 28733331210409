import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import LoginIcon from '@mui/icons-material/Login';
import Dark from "../../global/dark";
const MenuOption = ({ setDarkMode, darkMode }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: {
          xs: "none",
          md: "flex",
          height: "4em",
          alignItems: "center",
        },
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "none",
          justifyContent: "flex-end",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <LoginIcon onClick={() => navigate("/admin")} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70%",
        }}
      >
        <Dark setDarkMode={setDarkMode} darkMode={darkMode} />
      </Box>
    </Box>
  );
};

export default MenuOption;
