import React from "react";
import { motion } from "framer-motion";

const SplitText = ({ children, ...rest }) => {
  let words = children.split(" ");
  return words.map((word, i) => {
    return (
      <div
        key={children + i}
        style={{
          display: "flex",
          overflow: "hidden",
        }}
      >
        <motion.div
          {...rest}
          style={{
            display: "flex",
            willChange: "transform",
          }}
          custom={i}
        >
          {word.replace("-", " ") + (i !== words.length - 1 ? "\u00A0" : "")}
        </motion.div>
      </div>
    );
  });
};
export default SplitText;
