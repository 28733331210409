import React, { useEffect, useState } from "react";
import image from "../../../assets/imgs/service.png";
import { useTheme, Stack, Typography, useMediaQuery } from "@mui/material";
import { ServicesStyled } from "./style";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "../../common/mainComponents/about";
import Cards from "../../common/global/commonCards";
import ServiceContent from "../../common/servicesComponents/serviceContent";
import DetailComponent from "../../common/servicesComponents/detailComponents";
import { motion, AnimatePresence } from "framer-motion";
import {
  data,
  detailDisplayer,
  detailSelector,
  technoDisplayer,
} from "../../../functions";
import TechIcon from "../../common/servicesComponents/technoIcon";

const ServicesComponents = () => {
  const [detail, setDetail] = useState(-1);
  const [current, setCurrent] = useState(false);
  const [slide, setSlide] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    setCurrent(detail);
    setSlide(!slide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);
  const isScreenSizeOver900 = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <ServicesStyled height={isScreenSizeOver900 ? "85%" : "fit-content"}>
      <Stack
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        sx={{
          width: "100%",
          height: { xs: "fit-content", md: "97%" },
          marginTop: ".7em",
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            initial={{ x: -700, opacity: 0, width: "40%" }}
            animate={{
              x: 0,
              opacity: 1,
              width: isScreenSizeOver900 ? "40%" : "100%",
              height: "100%",
              transition: { delay: 0.3 },
            }}
            exit={{ x: -700, opacity: 0, width: "40%" }}
          >
            <About
              bgColor={theme.palette.secondBox.main}
              imgOn={true}
              width="100%"
            >
              <Stack
                spacing={2}
                alignItems="center"
                height="100%"
                sx={{ transition: "height .3s ease-in" }}
              >
                <Stack direction="row" justifyContent="center" width="100%">
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "4vw", md: "4vw" },
                      fontFamily: "Oswald, sans-serif",
                      fontWeight: "800",
                      color: "white",
                    }}
                  >
                    Nos Services
                  </Typography>
                </Stack>

                {detail !== -1 ? (
                  <DetailComponent
                    data={
                      detailDisplayer(detail) ? detailDisplayer(detail) : []
                    }
                  />
                ) : (
                  <img src={image} alt="img" />
                )}
              </Stack>
              <TechIcon
                data={technoDisplayer(detail) ? technoDisplayer(detail) : []}
              />
            </About>
          </motion.div>
        </AnimatePresence>

        <Stack
          columnGap={1}
          rowGap={1}
          flexWrap="wrap"
          justifyContent="center"
          direction={{ xs: "row", md: "column" }}
          sx={{
            width: { xs: "100%", md: "60%" },
            height: "100%",
            
          }}
        >
          {data.map((item, i) => (
            <AnimatePresence mode="wait">
              <motion.div
                initial={{ y: -700, opacity: 0, width: "49.5%", height: "49%" }}
                animate={{
                  y: 0,
                  opacity: 1,
                  width: isScreenSizeOver900 ? "49.5%" : "100%",
                  height: isScreenSizeOver900 ? "49%" : "100%",
                  transition: { delay: `0.${6 + i}` },
                }}
                exit={{ y: -700, opacity: 0, width: "49.5%", height: "49%" }}
              >
                <Cards
                  key={i}
                  bg={theme.palette.background.paper}
                  width={{ xs: "100%", md: "100%" }}
                  height={{ xs: "50%", md: "100%" }}
                >
                  <ServiceContent
                    chip={item.chip}
                    title={item.title}
                    discription={item.discription}
                    detail={detail}
                    active={detail === i ? true : false}
                    onClick={() => {
                      current === detailSelector(item.chip)
                        ? setDetail(-1)
                        : setDetail(detailSelector(item.chip));
                    }}
                  />
                </Cards>
              </motion.div>
            </AnimatePresence>
          ))}
        </Stack>
      </Stack>
    </ServicesStyled>
  );
};

export default ServicesComponents;
