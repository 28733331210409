import { useNavigate } from "react-router";
import { useState } from "react";
import { useTheme } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { Card, Stack, Typography } from "@mui/material";
import TextAnimation from "../textLoop";
import bg from "../../../../assets/imgs/img1.png";

const About = ({ children, bgColor, width, imgOn, setActiveLink }) => {
  const theme = useTheme();
  const phrases = [["DSC Technology Company", "Digital Service Consulting"]];
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Card
      sx={{
        backgroundColor: bgColor,
        color: theme.palette.text.thirdly,
        width: { xs: "100%", md: width ? width : "40%" },
        height: "100%",
        backgroundImage: imgOn ? `url(${bg})` : "none",
        backgroundBlendMode: "color-burn",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: "1.5em 1.5em",
        overflow: "hidden",
        scrollbarWidth: "thin",
        transition: ".2s ease-in-out",
        position: "relative",
      }}
    >
      {children ? (
        children
      ) : (
        <AnimatePresence mode="wait">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
          >
            <p
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{
                position: "absolute",
                top: "-24px",
                color: "#E2E2E2",
                zIndex: "2",
                fontSize: !isHovered ? "4.5vw" : "5vw",
                margin: 0,
                fontWeight: 900,
                right: "0",
                opacity: !isHovered ? 0.4 : 1,
                cursor: "pointer",
                transition: ".2s ease",
              }}
              onClick={() => {
                navigate("/L'entreprise");
                handleLinkClick(1);
              }}
            >
              L'entreprise
            </p>
            <Stack
              spacing={4}
              width="100%"
              height="100%"
              justifyContent="center"
              sx={{ color: "red" }}
            >
              <TextAnimation
                phrases={phrases[0]}
                sx={{ transform: "translateX(-10em)" }}
                flou={isHovered}
              />
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "4vw", md: "1.5vw" },
                  transition: ".2s ease-in-out",
                  color: isHovered ? "transparent" : "white",
                  textShadow: isHovered
                    ? "0 0 10px rgba(255, 255, 255, 0.5)"
                    : "none",
                }}
              >
                CCTV - Reseaux - system anti incendie
              </Typography>
            </Stack>
          </motion.div>
        </AnimatePresence>
      )}
    </Card>
  );
};

export default About;
