import styled from "@emotion/styled";

export const AboutStyled = styled.main`
  width: 100%;
  height: ${({ height }) => height};
  img {
    object-fit: contain;
  }
`;

export const LogoClients = styled.div`
  img {
    margin: 0em auto;
  }
`;

export const LogoPartenaire = styled.div`
  img {
    margin: 0em auto;
    padding: 2em 0;
  }
`;
