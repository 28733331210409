import { Avatar, Typography } from "@mui/material";

const IconeTitle = ({ title, icon }) => {
  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: "secondary.main", alignSelf: "center" }}>
        {icon}
      </Avatar>
      <Typography
        component="overline"
        variant="overline"
        sx={{ alignSelf: "center" }}
      >
        {title}
      </Typography>
    </>
  );
};

export default IconeTitle;
