import React from "react";
import { Navigate, Outlet } from "react-router-dom";
const useAuth = () => {
  const admin = localStorage.getItem("auth");
  if (admin) {
    return true;
  } else {
    return false;
  }
};
const ProtectedRoute = () => {
  const auth = useAuth();
  return auth ? <Outlet /> : <Navigate to="/sign-in" />;
};

export default ProtectedRoute;
