import React, { useState } from "react";
import { motion } from "framer-motion";
import { Box, MenuItem, MenuList, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";
import "./style.css";
import { useLocation } from "react-router";
import { useTheme } from "@emotion/react";
const pages = [
  { label: "Acceuil", path: "/" },
  { label: "L'entreprise", path: "/L'entreprise" },
  { label: "Services", path: "/Services" },
  { label: "Contact", path: "/Contact" },
];

const Navigation = ({ column, display, toggleDrawer, activeLink, setActiveLink }) => {
  const location = useLocation();
  const theme = useTheme();
  const isScreenSizeOver900 = useMediaQuery(theme.breakpoints.up("md"));
  const linkVariants = {
    active: {
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.2,
      },
      color: theme.palette.primary.main,
    },
    inactive: {
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.2,
      },
      color: theme.palette.text.primary,
    },
  };
  const barVariants = {
    active: {
      width: "50%",
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.2,
      },
    },
    inactive: {
      width: "0%",
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.2,
      },
    },
  };
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <Box
      sx={{
        flexGrow: 3,
        display: display,
        alignItems: "center",
      }}
    >
      <MenuList
        id="composition-menu"
        aria-labelledby="composition-button"
        sx={{
          display: column,
          width: "100%",
        }}
      >
        {pages.map((page, i) => (
          <MenuItem
            key={i}
            disableRipple
            sx={{
              cursor: "none",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
              fontSize: "1.2em",
            }}
          >
            <motion.div
              variants={linkVariants}
              animate={activeLink === i ? "active" : "inactive"}
              onClick={() => handleLinkClick(i)}
              style={{
                position: "relative",
              }}
            >
              <NavLink
                to={page.path}
                style={{
                  cursor: "pointer",
                  fontSize: ".8em",
                }}
                onClick={() => toggleDrawer(false)}
              >
                {page.label}
              </NavLink>

              <motion.div
                className="nav-bar"
                variants={barVariants}
                animate={
                  location.pathname === page.path ? "active" : "inactive"
                }
                style={{
                  position: "absolute",
                  top: "1.7em",
                  left: "0em",
                  height: ".25em",
                  borderRadius: "2em",
                  backgroundColor: isScreenSizeOver900
                    ? theme.palette.primary.main
                    : null,

                  transformOrigin: "left top",
                }}
              />
            </motion.div>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};

export default Navigation;
