import IconeTitle from "../../../../global/iconTitle";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AddIcon from "@mui/icons-material/Add";
import { Stack, TextField, Tooltip, Button } from "@mui/material";
const ProduitAdd = () => {
  return (
    <Stack width="50%" spacing={2} margin="0 auto">
      <IconeTitle title="Ajouter un Produit" icon={<ShoppingBasketIcon/>}/>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Nom du produit"
        autoFocus
      />
      <Tooltip
        title={
          <ul>
            <li>Au moins une lettre majuscule [A-Z]</li>
            <li>Au moins un caractère spécial [!@#$%^&*]</li>
            <li>Au moins six caractères en tout [a-zA-Z0-9!@#$%^&*]</li>
          </ul>
        }
      >
        <TextField
          margin="normal"
          required
          fullWidth
          name="Mot de passe"
          type="password"
        />
      </Tooltip>
      <Tooltip
        title={
          <ul>
            <li>Réécrivez votre mot de passe correctement</li>
          </ul>
        }
      >
        <TextField
          margin="normal"
          required
          fullWidth
          name="Confirmez votre mot de passe"
          label="Confirmez votre mot de passe"
          type="password"
        />
      </Tooltip>

      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Button variant="contained" color="success" endIcon={<AddIcon />}>
          Ajouter
        </Button>
        <Button variant="contained" color="secondary">
          Reset
        </Button>
      </Stack>
    </Stack>
  );
};

export default ProduitAdd;
