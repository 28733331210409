import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ServicesComponent = ({ data }) => {
  const theme = useTheme();
  return (
    <div style={{ overflowY: "auto", scrollbarWidth: "thin" }}>
      {data.map((item, i) => (
        <Accordion key={i}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{item[0]}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <Typography>{item[1]}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
export default ServicesComponent;
