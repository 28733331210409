import React, { useState } from "react";
import { Box, Divider, Drawer, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Navigation from "../navigation";
import "./style.css";
import CloseIcon from "@mui/icons-material/Close";
import Dark from "../../global/dark";
const MenuMobile = ({ setDarkMode, darkMode, toggleDrawer, open }) => {
  return (
    <Box
      sx={{
        justifyContent: "flex-end",
        flexGrow: 1,
        zIndex: "999",
        padding: "1.3em 0",
        display: { xs: "flex", md: "none" },
      }}
    >
      <MenuIcon
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => toggleDrawer(true)}
        sx={{
          marginRight: "2em",
          fontSize: "1.8em",
        }}
      />

      <Drawer
        open={open}
        onClose={() => toggleDrawer(false)}
        anchor="right"
        width="50em"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "1em 0",
          }}
        >
          <Dark setDarkMode={setDarkMode} darkMode={darkMode} />
          <CloseIcon onClick={() => toggleDrawer(false)} />
        </Box>
        <Divider />
        <Navigation
          display={{ xs: "flex", md: "none" }}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
    </Box>
  );
};

export default MenuMobile;
