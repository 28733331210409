import styled from "@emotion/styled";

export const FooterStyled = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${({ height }) => height};
  align-items: center;
  margin-top: 1em;
  color: ${({ theme }) => theme.palette.text.secondary};
  flex-wrap: wrap;
  margin-bottom: ${({ margin }) => margin};
  p {
    margin: 0;
    line-height: 0;
  }
`;
