import { Avatar, Stack, useTheme } from "@mui/material";
const TechIcon = ({ data }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing="1.5em"
      padding="0em 1em"
      sx={{
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        position: "absolute",
        bottom: "0%",
        left: "0%",
        scrollbarWidth: "thin",
        scrollSnapType: "x mandatory",
        "& > *": {
          scrollSnapAlign: "center",
        },
        "::-webkit-scrollbar": { display: "none" },
        overflow: "auto",
        transition: ".2s ease-in",
      }}
    >
      {data.map((item, i) => (
        <Avatar
          key={i}
          alt="Remy Sharp"
          src={item}
          sx={{ width: 70, height: 70 }}
        />
      ))}
    </Stack>
  );
};

export default TechIcon;
