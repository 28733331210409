import { Routes, Route } from "react-router-dom";
import AdminManager from "../components/common/adminComponents/adminManager";
import DisplayControle from "../components/common/adminComponents/display";
import PubManager from "../components/common/adminComponents/pubManager";
import Admin from "../screens/admin";
import Contact from "../screens/contact";
import Home from "../screens/home";
import Projects from "../screens/Qui-sommes-nous";
import Services from "../screens/services";
import SignIn from "../screens/Sign-in";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

const Screens = ({ setDarkMode, darkMode, activeLink, setActiveLink }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Home activeLink={activeLink} setActiveLink={setActiveLink} />}
      />
      <Route path="/L'entreprise" element={<Projects />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/Contact" element={<Contact />} />

      <Route path="/Sign-in" element={<PublicRoute />}>
        <Route path="/Sign-in" element={<SignIn />} />
      </Route>
      <Route path="/admin/" element={<ProtectedRoute />}>
        <Route
          path="/admin/"
          element={<Admin setDarkMode={setDarkMode} darkMode={darkMode} />}
        >
          <Route path="compte" element={<AdminManager />} />
          <Route path="publicite" element={<DisplayControle />} />
          <Route path="add" element={<PubManager />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Screens;
