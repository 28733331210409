import { Button, Chip, Stack, Typography } from "@mui/material";

const ServiceContent = ({ chip, title, discription, onClick, active }) => {
  return (
    <Stack spacing={2} padding="0em .5em">
      <Chip
        size="small"
        label={chip}
        color="secondBox"
        sx={{ alignSelf: "flex-start" }}
      />
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: "5vw", md: "1.2vw" },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="overline"
        sx={{
          fontSize: { xs: "2.5vw", md: ".7vw" },
          lineHeight: "1.5em",
          color: "gray",
        }}
      >
        {discription}
      </Typography>
      <Button
        variant={active ? "contained" : "outlined"}
        size="small"
        sx={{ alignSelf: "flex-start" }}
        onClick={onClick}
        color="secondBox"
      >
        Détails
      </Button>
    </Stack>
  );
};

export default ServiceContent;
