import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#0051ad",
      light: "#1c72d3",
      dark: "#0c4381",
      contrastText: "#fff",
    },
    secondary: {
      main: "#212121",
      light: "#1b1b1b",
      dark: "#000000",
      contrastText: "#212121",
    },
    thirdly: {
      main: "#f7d24c",
      light: "#f5fc97",
      dark: "#e5f413",
      contrastText: "#fff",
    },
    firstBox: {
      main: "#175676",
      light: "#95f7f8",
      dark: "#23c4c6",
      contrastText: "#fff",
    },
    secondBox: {
      main: "#4BA3C3",
      light: "#63b5d3",
      dark: "#3a8ba8",
      contrastText: "#fff",
    },
    thirdBox: {
      main: "#FFACAC",
      light: "#eaeaea",
      dark: "#adadad",
      contrastText: "#fff",
    },
    QuartBox: {
      main: "#fcc2b0",
      light: "#eaeaea",
      dark: "#adadad",
      contrastText: "#fff",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
      thirdly: "#ffffff",
      disabled: "#9e9e9e",
      hint: "#bdbdbd",
    },
    background: {
      paper: "#fff",
      default: "#e2e2e2",
    },
  },
  typography: {
    fontFamily: "Raleway, sans-serif",
  },
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#0051ad",
      light: "#00337C",
      dark: "#181D31",
      contrastText: "#fff",
    },
    secondary: {
      main: "#3d3d3d",
      light: "#bdbdbd",
      dark: "#9e9e9e",
      contrastText: "#fff",
    },

    thirdly: {
      main: "#F5FF6A",
      light: "#f5fc97",
      dark: "#e5f413",
      contrastText: "#fff",
    },
    firstBox: {
      main: "#0A2647",
      light: "#95f7f8",
      dark: "#23c4c6",
      contrastText: "#fff",
    },
    secondBox: {
      main: "#063b56",
      light: "#a2f8b3",
      dark: "#1F8A70",
      contrastText: "#fff",
    },
    thirdBox: {
      main: "#d86c6c",
      light: "#eaeaea",
      dark: "#adadad",
      contrastText: "#fff",
    },
    QuartBox: {
      main: "#BC6A4F",
      light: "#eaeaea",
      dark: "#adadad",
      contrastText: "#fff",
    },
    text: {
      primary: "#fff",
      secondary: "#c4c4c4",
      thirdly: "#212121",
      disabled: "#9e9e9e",
      hint: "#bdbdbd",
    },
    background: {
      paper: "#212121",
      default: "#101010",
    },
  },
  typography: {
    fontFamily: "Raleway, sans-serif",
  },
});
