import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
export const AppContainer = styled.section`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => useTheme().palette.background.defautl};
`;
export const GlobalWrapper = styled.section`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  flex-direction: column;
`;
