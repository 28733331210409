import { Avatar, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Dark from "../../global/dark";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const AppNav = ({ open, setOpen, setDarkMode, darkMode }) => {
  const theme = useTheme();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const name = localStorage.getItem("user_name");
  return (
    <AppBar
      position="absolute"
      open={open}
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <Toolbar
        sx={{
          pr: "24px",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 4 }}
        >
          Dashboard
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            component="h5"
            variant="h5"
            color="inherit"
            sx={{ flexGrow: 1, fontSize: ".8em" }}
          >
            {name}
          </Typography>
          <Avatar sx={{ bgcolor:theme.palette.firstBox.main }}>
            {name.charAt(0).toUpperCase()}
          </Avatar>
          <Dark setDarkMode={setDarkMode} darkMode={darkMode} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AppNav;
