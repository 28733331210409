import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
const SocialIcone = () => {
  return (
    <>
      <a href="https://www.facebook.com/DsC.Technology.DZ/" target="_blank">
        <FacebookIcon
          sx={{
            fontSize: { xs: "4w", md: "2.3vw" },
            cursor: "pointer",
            "&:hover": {
              color: "#3b5998",
            },
          }}
        />
      </a>

      <a
        href="https://www.linkedin.com/in/dsc-technology-848a3b248/"
        target="_blank"
      >
        <LinkedInIcon
          sx={{
            fontSize: { xs: "4w", md: "2.3vw" },
            cursor: "pointer",
            "&:hover": {
              color: "#0e76a8",
            },
          }}
        />
      </a>

      <a href="https://www.instagram.com/dsctechnology/" target="_blank">
        {" "}
        <InstagramIcon
          sx={{
            fontSize: { xs: "4w", md: "2.3vw" },
            cursor: "pointer",
            "&:hover": {
              color: "#cc2366",
            },
          }}
        />
      </a>
    </>
  );
};

export default SocialIcone;
