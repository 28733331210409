import React, { useState } from "react";
import { NavBarStyled } from "./style";
import { Box } from "@mui/material";
import logo from "../../../assets/logo/logo-web.png";
import logoLight from "../../../assets/logo/logo-web-light.png";
import Logo from "../../common/navComponents/logo";
import MenuOption from "../../common/navComponents/menuOption";
import Navigation from "../../common/navComponents/navigation";
import MenuMobile from "../../common/navComponents/menuMobile";
import BottomNavigation from "../../common/navComponents/bottomNav";
import { useTheme } from "@mui/material";
const NavBare = ({ setDarkMode, darkMode, activeLink, setActiveLink }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const toggleDrawer = (isOpen) => {
    setOpen(isOpen);
  };
  return (
    <NavBarStyled>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          zIndex: "999",
          borderRadius: "inherit",
          backgroundColor: theme.palette.background.paper,
          padding: { xs: ".2em 0", md: ".5em 0" },
        }}
      >
        <Logo
          src={darkMode ? logoLight : logo}
          position="center"
          padding="0em"
        />
        <Navigation
          column="flex"
          display={{ xs: "none", md: "flex" }}
          toggleDrawer={toggleDrawer}
          activeLink={activeLink}
          setActiveLink={setActiveLink}
        />
        <MenuOption setDarkMode={setDarkMode} darkMode={darkMode} />
        <MenuMobile
          setDarkMode={setDarkMode}
          darkMode={darkMode}
          toggleDrawer={toggleDrawer}
          open={open}
        />
        <BottomNavigation />
      </Box>
    </NavBarStyled>
  );
};

export default NavBare;
