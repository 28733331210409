import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";

export const NavBarStyled = styled.nav`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => useTheme().palette.background.paper};
  position: sticky;
  top: 0;
  z-index: 999;
  border-radius: 6em;
`;
