import { Box, useTheme } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import NightsStayIcon from "@mui/icons-material/NightsStay";
const Dark = ({ setDarkMode, darkMode }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "3em",
        height: "1.6em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        borderRadius: "2em",
        position: "relative",
        overflow: "hidden",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 1px 3px 0px inset",
        cursor: "pointer",
      }}
      onClick={() => setDarkMode(!darkMode)}
    >
      <NightsStayIcon
        sx={{
          position: "absolute",
          transform: darkMode ? "translateX(.65em)" : "translateX(-1.7em)",
          transition: ".2s ease-in-out",
          color: theme.palette.primary.light,
          fontSize: "1.2em",
        }}
      />
      <LightModeIcon
        sx={{
          position: "absolute",
          transform: darkMode ? "translateX(2.2em)" : "translateX(-.5em)",
          transition: ".2s ease-in-out",
          color: theme.palette.thirdly.main,
          fontSize: "1.2em",
        }}
      />
    </Box>
  );
};

export default Dark;
