import { useState } from "react";
import { Card, Typography, useTheme, useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import img1 from "../../../../assets/imgs/slider/img1.jpg";
import img2 from "../../../../assets/imgs/slider/img2.jpg";
import img3 from "../../../../assets/imgs/slider/img3.jpg";
import img4 from "../../../../assets/imgs/slider/img4.jpg";
import img5 from "../../../../assets/imgs/slider/img5.jpg";
import img6 from "../../../../assets/imgs/slider/img6.jpg";
import img7 from "../../../../assets/imgs/slider/img7.jpg";
import img8 from "../../../../assets/imgs/slider/img8.jpg";
import img1AVIF from "../../../../assets/imgs/slider/img1.avif";
import img2AVIF from "../../../../assets/imgs/slider/img2.avif";
import img3AVIF from "../../../../assets/imgs/slider/img3.avif";
import img4AVIF from "../../../../assets/imgs/slider/img4.avif";
import img5AVIF from "../../../../assets/imgs/slider/img5.avif";
import img6AVIF from "../../../../assets/imgs/slider/img6.avif";
import img7AVIF from "../../../../assets/imgs/slider/img7.avif";
import img8AVIF from "../../../../assets/imgs/slider/img8.avif";
import { useNavigate } from "react-router";
import { motion, AnimatePresence } from "framer-motion";
import "./style.css";
const Slide = ({ setActiveLink }) => {
  const navigate = useNavigate();
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  const theme = useTheme();
  const [currentTitre, setCurrentTitre] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const isScreenSizeOver900 = useMediaQuery(theme.breakpoints.up("md"));
  const titres = [
    "Infrastructures IT & téléphonie",
    "Caméra de surveillance CCTV",
    "System anti-incendie",
    "Développement informatiques",
  ];
  const imagesJPG = [img1, img2, img3, img4, img5, img6, img7, img8];
  const imagesAVIF = [
    img1AVIF,
    img2AVIF,
    img3AVIF,
    img4AVIF,
    img5AVIF,
    img6AVIF,
    img7AVIF,
    img8AVIF,
  ];
  const variants = {
    enter: {
      x: "100vw",
    },
    center: {
      x: 0,
    },
    exit: {
      x: "-100vw",
    },
  };

  const transition = {
    type: "tween",
    duration: 0.5,
  };

  const onAnimationComplete = () => {
    setTimeout(() => {
      setCurrentTitre((currentTitre + 1) % titres.length);
    }, 9200);
  };

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    autoplaySpeed: 4500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: false,
  };

  const supportsAVIF = (() => {
    const img = new Image();
    return typeof img.decode === "function";
  })();
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Card
      elevation={0}
      sx={{
        width: isScreenSizeOver900 ? "65%" : "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        backgroundColor: "transparent",
      }}
    >
      <p
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          position: "absolute",
          top: "-32px",
          color: "#E2E2E2",
          zIndex: "2",
          fontSize: !isHovered ? "4.5vw" : "6vw",
          margin: 0,
          fontWeight: 900,
          left: "-5px",
          opacity: !isHovered ? 0.4 : 1,
          cursor: "pointer",
          transition: ".2s ease",
        }}
        onClick={() => {
          navigate("/Services");
          handleLinkClick(2);
        }}
      >
        Nos Services
      </p>
      <AnimatePresence mode="wait">
        <motion.div
          initial={{ y: 700, opacity: 0, width: "100%", height: "100%" }}
          animate={{
            y: 0,
            opacity: 1,
            width: "100%",
            height: "100%",
            transition: { delay: 0.0 },
          }}
          exit={{ y: 700, opacity: 0, width: "100%", height: "100%" }}
        >
          <div className="slide-content">
            <Slider {...settings}>
              {supportsAVIF
                ? imagesAVIF.map((img, i) => (
                    <div key={i} style={{ height: "100%" }}>
                      <img
                        src={img}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          filter: isHovered ? " blur(4px) " : "none",
                        }}
                        alt="slider 1"
                      />
                    </div>
                  ))
                : imagesJPG.map((img, i) => (
                    <div key={i} style={{ height: "100%" }}>
                      <img
                        src={img}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          filter: isHovered ? " blur(4px)" : "none",
                        }}
                        alt="slider 1"
                      />
                    </div>
                  ))}
            </Slider>
          </div>
        </motion.div>
      </AnimatePresence>
      <AnimatePresence>
        <motion.div
          key={currentTitre}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={transition}
          onAnimationComplete={onAnimationComplete}
          style={{
            fontSize: "2em",
            position: "absolute",
            bottom: "0",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: "1em",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Oswald, sans-serif",
              fontWeight: "900",
              color: isHovered ? "transparent" : "white",
              textShadow: isHovered
                ? "0 0 10px rgba(255, 255, 255, 0.5)"
                : "none",
              textAlign: "center",
              fontSize: { xs: "8vw", md: "3vw" },
            }}
          >
            {titres[currentTitre]}
          </Typography>
        </motion.div>
      </AnimatePresence>
    </Card>
  );
};

export default Slide;
