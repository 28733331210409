import { Box, Card, Typography, useTheme } from "@mui/material";
import SocialIcone from "../../global/socialIcone";
const Social = ({ height, width }) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.paper,
        width: width ? width : "100%",
        height: { xs: "18em", md: height ? height : "100%" },
        display: { xs: "none", md: "flex" },
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0em 1.5em",
        transition: ".2s ease-in-out",
        "&:hover": {
          boxShadow: "none",
        },
      }}
    >
      <Box>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "8vw", md: "1vw" },
          }}
        >
          Suivez nous
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <SocialIcone />
      </Box>
    </Card>
  );
};

export default Social;
