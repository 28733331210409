import { KEY_SECRET_USER } from "../config";
import KJUR from "jsrsasign";

export const tokenCheckIn = (token) => {
  if (!token) return false;
  try {
    const verified = KJUR.jws.JWS.verifyJWT(token, KEY_SECRET_USER, {
      alg: ["HS256"],
    });
    if (verified) {
      const tokenParts = token.split(".");
      const payload = JSON.parse(KJUR.b64utoutf8(tokenParts[1]));
      const data = payload.data;
      console.log(data);
      return data;
    }
  } catch (err) {
    return false;
  }
};
