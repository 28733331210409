import Cards from "../../common/global/commonCards";
import cdmi from "../../../assets/ref/cdmi.png";
import biopharm from "../../../assets/ref/biopharm.png";
import distrim from "../../../assets/ref/distrim.png";
import gefco from "../../../assets/ref/gefco.png";
import greensky from "../../../assets/ref/greensky.png";
import delta from "../../../assets/ref/delta.png";
import tcs from "../../../assets/ref/tcs.png";
import trust from "../../../assets/ref/trust.png";
import binoyaux from "../../../assets/ref/binoyaux.png";
import wst from "../../../assets/ref/wst.png";
import nhsm from "../../../assets/ref/nhsm.png";
import ehs from "../../../assets/ref/ehs.png";
import dell from "../../../assets/partenaires/dell.png";
import bitfender from "../../../assets/partenaires/bitfender.png";
import fortinet from "../../../assets/partenaires/fortinet.png";
import veaam from "../../../assets/partenaires/veaam.png";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import { AboutStyled, LogoClients, LogoPartenaire } from "./style";
import {
  Avatar,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "react-slick";
import "./style.css";
const EntrepriseComponent = () => {
  const theme = useTheme();
  const isScreenSizeOver900 = useMediaQuery(theme.breakpoints.up("md"));
  const ref = [
    biopharm,
    trust,
    gefco,
    cdmi,
    binoyaux,
    distrim,
    ehs,
    nhsm,
    greensky,
    delta,
    wst,
    tcs,
  ];
  const part = [dell, bitfender, fortinet, veaam];
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isScreenSizeOver900 ? 5 : 2,
    slidesToScroll: isScreenSizeOver900 ? 5 : 2,
    pauseOnHover: false,
  };
  const settingsPart = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: isScreenSizeOver900 ? 2 : 1,
    slidesToScroll: isScreenSizeOver900 ? 2 : 1,
    pauseOnHover: false,
  };
  return (
    <AboutStyled height={isScreenSizeOver900 ? "85%" : "fit-content"}>
      <Stack
        component="section"
        spacing={2}
        direction="column"
        sx={{
          width: "100%",
          height: { xs: "fit-content", md: "97%" },
        }}
      >
        <Stack
          component="section"
          spacing={2}
          direction={{ xs: "column", md: "row" }}
          sx={{
            width: "100%",
            height: { xs: "fit-content", md: "100%" },
            marginTop: ".7em",
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ x: -700, opacity: 0, width: "100%" }}
              animate={{
                x: 0,
                opacity: 1,
                width: "100%",
                transition: { delay: 0.4 },
              }}
              exit={{ x: -700, opacity: 0, width: "100%" }}
            >
              <Cards
                bg={theme.palette.background.paper}
                width={{ xs: "100%", md: "100%" }}
                height={{ xs: "50%", md: "100%" }}
              >
                <Stack
                  width="100%"
                  spacing={6}
                  sx={{ overflowY: "auto", overflowX: "hidden" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "4vw", md: "1.5vw" },
                      fontFamily: "Oswald, sans-serif",
                      fontWeight: "800",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Notre mission
                    <FlagCircleIcon />
                  </Typography>

                  <Stack
                    style={{
                      overflowY: "auto",
                      overflowX: "hidden",
                      scrollbarWidth: "thin",
                    }}
                    sx={{
                      fontFamily: "Oswald, sans-serif",
                      textAlign: "justify",
                      padding: "0em 2em",
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: { xs: "3vw", md: "1.2vw" },
                        fontWeight: "500",
                        marginBottom: "1em",
                      }}
                    >
                      DSC Technology : Votre partenaire d'exception en solutions
                      informatiques et systèmes courants faible
                    </Typography>
                    <Typography
                      paragraph
                      sx={{
                        fontSize: isScreenSizeOver900 ? "1vw" : "3vw",
                        margin: 0,
                      }}
                    >
                      DSC Technology a été créé le 19/09/2021 par une équipe de
                      jeunes ingénieurs hautement qualifié dans, elle se
                      positionne en tant qu’Entreprise novatrice spécialisé dans
                      l’étude et l’intégration des solutions informatiques et
                      des systèmes courant faibles parfaitement optimisé pour
                      répondre aux besoins spécifiques de votre Entreprise .
                      Nous nous engageons à soutenir et accompagner nos clients
                      dans leur croissance en leur offrant une vaste gamme de
                      solutions adaptées à leurs besoins à long terme.. Nous
                      proposons des services complets, tels que :
                    </Typography>
                    <ul
                      style={{
                        fontSize: isScreenSizeOver900 ? "1vw" : "3vw",
                      }}
                    >
                      <li>L’étude d'opportunité.</li>
                      <li>le cadrage de projet. </li>
                      <li>
                        la rédaction de cahiers des charges, l'installation, les
                        tests, la mise en exploitation.
                      </li>
                      <li>la formation, le transfert de compétences.</li>
                      <li>la maintenance.</li>
                    </ul>
                    <Typography
                      paragraph
                      sx={{
                        fontSize: { xs: "3vw", md: "1vw" },
                      }}
                    >
                      Faites confiance à notre équipe expérimentée pour vous
                      fournir des solutions sur mesure, répondant à vos
                      exigences spécifiques et favorisant votre succès à long
                      terme.
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { xs: "3vw", md: "1vw" },
                        fontFamily: "Oswald, sans-serif",
                        fontWeight: "800",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Nos Clients
                    </Typography>
                    <Stack direction="column" padding="1.5em 0em">
                      <Slider {...settings}>
                        {ref.map((item, i) => (
                          <LogoClients>
                            <Avatar
                              key={i}
                              alt="Logo partenaires"
                              src={item}
                              sx={{
                                width: isScreenSizeOver900 ? 150 : 100,
                                height: isScreenSizeOver900 ? 150 : 100,
                                backgroundColor: "white",
                                margin: "0 auto",
                              }}
                            />
                          </LogoClients>
                        ))}
                      </Slider>
                    </Stack>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { xs: "3vw", md: "1vw" },
                        fontFamily: "Oswald, sans-serif",
                        fontWeight: "800",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Nos partenaires
                    </Typography>
                    <Typography
                      paragraph
                      sx={{
                        fontSize: { xs: "3vw", md: "1vw" },
                        padding: "1em 0",
                      }}
                    >
                      Ces partenaires exceptionnels sont le reflet de notre
                      engagement à fournir des solutions de qualité et à établir
                      des relations durables avec nos clients. Ensemble, nous
                      travaillons main dans la main pour favoriser votre succès
                      à long terme et répondre aux défis de votre entreprise.
                    </Typography>
                    <Stack direction="column">
                      <Slider {...settingsPart}>
                        {part.map((item, i) => (
                          <LogoPartenaire>
                            <Avatar
                              key={i}
                              alt="Logo partenaires"
                              src={item}
                              sx={{
                                width: 200,
                                height: 200,
                                backgroundColor: "white",
                                margin: "0 auto",
                              }}
                            />
                          </LogoPartenaire>
                        ))}
                      </Slider>
                    </Stack>
                  </Stack>
                </Stack>
              </Cards>
            </motion.div>
          </AnimatePresence>
        </Stack>
      </Stack>
    </AboutStyled>
  );
};

export default EntrepriseComponent;
