import { Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FooterStyled } from "./style";

const Copyright = () => {
  const theme = useTheme();
  const isScreenSizeOver900 = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <FooterStyled
      margin={isScreenSizeOver900 ? "0" : "1em"}
      height={isScreenSizeOver900 ? "100%" : "2em"}
    >
      <Typography variant="body2">DSC Technology</Typography>
      <Typography variant="body2">
        {"Copyright © "}
        <Link color="inherit" href="https://www.linkedin.com/in/nazimaitali/">
          Elemental Smart Web
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    </FooterStyled>
  );
};
export default Copyright;
