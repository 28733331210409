import styled from "@emotion/styled";

export const MainStyled = styled.main`
  width: 100%;
  height: ${({ height }) => height};
  img{
    object-fit: contain;
  }
 
`;
