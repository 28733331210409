import Axios from "axios";
import { tokenCheckIn } from "../helpers";
export const signInUser = async (navigate, loginUser) => {
  try {
    const response = await Axios.post(
      "http://localhost:8080/users/sign-in",
      loginUser
    );
    if (response.status !== 200) {
      return console.log("error 404");
    }
    const verify = await tokenCheckIn(response.data.user_token);
    if (verify) {
      console.log("verify in api call");
      localStorage.setItem("auth", true);
      localStorage.setItem("id", verify.id);
      localStorage.setItem("user_name", verify.user_name);
      localStorage.setItem("role", verify.role);

      return navigate("/admin");
    }
  } catch (err) {
    console.log(err.response.data.msg);
  }
};
export const fetchParameter = async (setParameter) => {
  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/data/email.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const data = await response.json();
    setParameter(data);
  } catch (error) {
    console.log(error);
  }
};
