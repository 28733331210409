import { SET_USER } from "../Actions/UserAction";
const initialState = {
  loginUser: {
    user_name: null,
    password: null,
    repeat_password: null,
    role: "admin",
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default userReducer;
