import React, { useState, useEffect } from "react";
import SplitText from "./split";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery, useTheme } from "@mui/material";

const TextAnimation = ({ phrases, flou }) => {
  const theme = useTheme();
  let [index, setIndex] = useState(0);
  const isScreenSizeOver900 = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % phrases.length);
    }, 3000);

    return () => clearInterval(interval);
  });

  return (
    <h1
      style={{
        color: flou ? "transparent" : "white",
        fontSize: isScreenSizeOver900 ? "3.5vw" : "12vw",
        position: "relative",
        margin: ".3em 0",
        textShadow: flou ? "0 0 10px rgba(255, 255, 255, 0.5)" : "none",
        transition: ".2s ease",
      }}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <SplitText
            initial={{ y: "100%" }}
            animate="visible"
            style={{ color: "white" }}
            variants={{
              visible: (i) => ({
                y: 0,
                transition: {
                  delay: i * 0.1,
                },
              }),
            }}
          >
            {phrases[index]}
          </SplitText>
        </motion.div>
      </AnimatePresence>
    </h1>
  );
};

export default TextAnimation;
