import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const BottomNav = () => {
  const [value, setValue] = useState(0);
  const ref = useRef(null);

  return (
    <Box sx={{ display: { xs: "flex", md: "none" } }} ref={ref}>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, width: "100vw" }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Facebook"
            icon={<FacebookIcon />}
            onClick={() =>
              window.open(
                "https://www.facebook.com/DsC.Technology.DZ/",
                "_blank"
              )
            }
          />
          <BottomNavigationAction
            label="Instagram"
            icon={<InstagramIcon />}
            onClick={() =>
              window.open("https://www.instagram.com/dsctechnology/", "_blank")
            }
          />
          <BottomNavigationAction
            label="LinkedIn"
            icon={<LinkedInIcon />}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/dsc-technology-848a3b248/",
                "_blank"
              )
            }
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default BottomNav;
