import { useTheme, Stack, useMediaQuery, Divider, Chip } from "@mui/material";
import { ContactStyled } from "./style";
import { motion, AnimatePresence } from "framer-motion";
import Formulaire from "../../common/contactComponents/form";
import Map from "../../common/contactComponents/map";
import Cards from "../../common/global/commonCards";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import HomeIcon from "@mui/icons-material/Home";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import qr from "../../../assets/qrcode/qrcode.jpg";
import SocialIcone from "../../common/global/socialIcone";

const ContactComponent = () => {
  const theme = useTheme();
  const isScreenSizeOver900 = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <ContactStyled>
      <Stack
        spacing={2}
        direction={{ xs: "column-reverse", md: "row" }}
        sx={{
          width: "100%",
          height: { xs: "fit-content", md: "97%" },
          marginTop: ".7em",
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            initial={{ x: 700, opacity: 0, width: "24%" }}
            animate={{
              x: 0,
              opacity: 1,
              width: isScreenSizeOver900 ? "24%" : "100%",

              transition: { delay: 0.6 },
            }}
            exit={{ x: 700, opacity: 0, width: "24%" }}
          >
            <Stack
              spacing={2}
              sx={{
                width: { xs: "100%", md: "100%" },
                height: "100%",
              }}
            >
              <Cards bg={theme.palette.primary.main} width="100%" height="100%">
                <Stack spacing={1.5} alignItems="center">
                  <img src={qr} alt="qr code" style={{ width: "75%" }} />

                  <Divider variant="middle" />
                  <Stack
                    spacing={2}
                    justifyContent="flex-start"
                    flexWrap="wrap"
                  >
                    <Chip
                      icon={<PhoneIphoneIcon />}
                      label="+213 770 12 87 69"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "fit-content",
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.secondary.contrastText,
                      }}
                    />
                    <Chip
                      icon={<PhoneIphoneIcon />}
                      label="+213 770 79 97 59"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "fit-content",
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.secondary.contrastText,
                      }}
                    />
                    <Chip
                      icon={<LocalPostOfficeIcon />}
                      label="contact@dsc-technology.com"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "fit-content",
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.secondary.contrastText,
                      }}
                    />
                    <Chip
                      icon={<HomeIcon />}
                      label="71 Abdi Mouloud , Birtouta, Algeria"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "fit-content",
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.secondary.contrastText,
                      }}
                    />
                  </Stack>

                  <Divider variant="middle" />
                  <Stack direction={"row"} alignSelf={"center"}>
                    <SocialIcone />
                  </Stack>
                </Stack>
              </Cards>
            </Stack>
          </motion.div>
        </AnimatePresence>
        <AnimatePresence mode="wait">
          <motion.div
            initial={{ x: -700, opacity: 0, width: "38%" }}
            animate={{
              x: 0,
              opacity: 1,
              width: isScreenSizeOver900 ? "38%" : "100%",
              transition: { delay: 0.8 },
            }}
            exit={{ x: -700, opacity: 0, width: "38%" }}
          >
            <Map />
          </motion.div>
        </AnimatePresence>
        <AnimatePresence mode="wait">
          <motion.div
            initial={{ x: 700, opacity: 0, width: "38%" }}
            animate={{
              x: 0,
              opacity: 1,
              width: isScreenSizeOver900 ? "38%" : "100%",
              transition: { delay: 1 },
            }}
            exit={{ x: -700, opacity: 0, width: "38%" }}
          >
            <Formulaire />
          </motion.div>
        </AnimatePresence>
      </Stack>
    </ContactStyled>
  );
};

export default ContactComponent;
