import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Copyright from "../../components/common/navComponents/copyright";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Tooltip, Box, Button, TextField, Paper, Grid } from "@mui/material";
import { signInUser } from "../../api";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import IconeTitle from "../../components/common/global/iconTitle";
import { SET_USER } from "../../redux/Actions/UserAction";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginUser } = useSelector((state) => state.login);

  return (
    <Grid container component="main" sx={{ height: "100%" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={0}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              position: "absolute",
              top: "0",
              right: "0",
              margin: "1em 1em",
            }}
            startIcon={<KeyboardReturnIcon />}
            onClick={() => navigate("/")}
          >
            Retour
          </Button>

          <IconeTitle title={"Connectez - vous"} icon={<LockOutlinedIcon />} />

          <Box
            component="form"
            noValidate
            onSubmit={() => console.log("submit")}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Nom d'utilisateur"
              name="Nom d'utilisateur"
              autoFocus
              onChange={(e) =>
                dispatch({
                  type: SET_USER,
                  payload: {
                    loginUser: {
                      ...loginUser,
                      user_name: e.target.value,
                    },
                  },
                })
              }
            />
            <Tooltip
              title={
                <ul>
                  <li>Au moins une lettre majuscule [A-Z]</li>
                  <li>Au moins un caractère spécial [!@#$%^&*]</li>
                  <li>Au moins six caractères en tout [a-zA-Z0-9!@#$%^&*]</li>
                </ul>
              }
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="Mot de passe"
                label="Mot de passe"
                type="password"
                onChange={(e) =>
                  dispatch({
                    type: SET_USER,
                    payload: {
                      loginUser: {
                        ...loginUser,
                        password: e.target.value,
                      },
                    },
                  })
                }
              />
            </Tooltip>
            <Tooltip
              title={
                <ul>
                  <li>Réécrivez votre mot de passe correctement</li>
                </ul>
              }
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="Confirmez votre mot de passe"
                label="Confirmez votre mot de passe"
                type="password"
                onChange={(e) =>
                  dispatch({
                    type: SET_USER,
                    payload: {
                      loginUser: {
                        ...loginUser,
                        repeat_password: e.target.value,
                      },
                    },
                  })
                }
              />
            </Tooltip>

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => signInUser(navigate, loginUser)}
            >
              se connecter
            </Button>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default SignIn;
