import EastIcon from "@mui/icons-material/East";
import { Card, Stack, Typography } from "@mui/material";

const Cards = ({ bg, title, height, width, children, onClick }) => {
  return (
    <Card
      sx={{
        backgroundColor: bg,
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems:"center",
        padding: "1.5em 1.5em",
        transition: ".2s ease-in-out",
        "&:hover": {
          boxShadow: "none",
        },
      }}
      onClick={onClick}
    >
      {children ? (
        children
      ) : (
        <>
          <Stack>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "10vw", md: "3vw" },
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="overline"
              sx={{ fontSize: { xs: "3vw", md: "1vw" } }}
            >
              En Savoir Plus
            </Typography>
          </Stack>
          <EastIcon
            sx={{ fontSize: { xs: "4w", md: "2vw" }, alignSelf: "flex-end" }}
          />
        </>
      )}
    </Card>
  );
};

export default Cards;
