import { useEffect, useState } from "react";
import NavBar from "./components/public/navBare";
import { ThemeProvider } from "@mui/material/styles";
import { AppContainer, GlobalWrapper } from "./styles/Container";
import { lightTheme, darkTheme } from "./styles/theme";
import { CssBaseline } from "@mui/material";
import Screens from "./routes";
import { useLocation } from "react-router";
import Copyright from "./components/common/navComponents/copyright";
const pages = [
  { label: "Acceuil", path: "/" },
  { label: "L'entreprise", path: "/L'entreprise" },
  { label: "Services", path: "/Services" },
  { label: "Contact", path: "/Contact" },
];
const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [showNavBar, setShowNavBar] = useState(false);
  const location = useLocation();
  const activePageIndex = pages.findIndex(
    (page) => page.path === location.pathname
  );
  const [activeLink, setActiveLink] = useState(
    activePageIndex !== -1 ? activePageIndex : 0
  );

  const commonPaths = ["/", "/L'entreprise", "/Services", "/Contact"];

  useEffect(() => {
    if (commonPaths.includes(location.pathname)) {
      setShowNavBar(true);
    } else {
      setShowNavBar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <AppContainer>
        <GlobalWrapper
          width={showNavBar ? "90%" : "100%"}
          height={showNavBar ? "90%" : "100%"}
        >
          {showNavBar ? (
            <NavBar
              setDarkMode={setDarkMode}
              darkMode={darkMode}
              activeLink={activeLink}
              setActiveLink={setActiveLink}
            />
          ) : null}
          <Screens
            setDarkMode={setDarkMode}
            darkMode={darkMode}
            activeLink={activeLink}
            setActiveLink={setActiveLink}
          />
          <Copyright />
        </GlobalWrapper>
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
