import { useNavigate } from "react-router";
import { handleLogout } from "../../../../functions";
import { useDispatch } from "react-redux";
import { styled, useTheme } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import {
  Toolbar,
  Divider,
  IconButton,
  Stack,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MainListItems from "../../../common/adminComponents/adminNavigation";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: useTheme().palette.background.default,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const AdminDrawer = ({ open, setOpen }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <MainListItems />
      <Stack>
        <ListItemButton
          sx={{ color: "red" }}
          onClick={() => handleLogout(navigate, dispatch)}
        >
          <ListItemIcon>
            <LogoutIcon sx={{ color: "red" }} />
          </ListItemIcon>
          Deconnecter
        </ListItemButton>
      </Stack>
    </Drawer>
  );
};

export default AdminDrawer;
