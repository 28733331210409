import React from "react";
import { Navigate, Outlet } from "react-router-dom";
const useAuth = () => {
  const admin = localStorage.getItem("auth");
  if (admin) {
    return true;
  } else {
    return false;
  }
};
const PublicRoute = () => {
  const auth = useAuth();
  return auth ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
