import { Button, Card, TextField, Box, Stack } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SendIcon from "@mui/icons-material/Send";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import IconeTitle from "../../global/iconTitle";
import { useEffect, useState } from "react";
import { fetchParameter } from "../../../../api";
const Formulaire = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [parameter, setParameter] = useState({});
  useEffect(() => {
    fetchParameter(setParameter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      sx={{
        width: { xs: "100%", md: "100%" },
        height: { xs: "30em", md: "100%" },
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "2em",
      }}
    >
      <IconeTitle title={"Contactez-Nous"} icon={<MailOutlineIcon />} />
      <Stack
        component="form"
        action={`https://formsubmit.co/${parameter.email}`}
        method="POST"
      >
        <input type="hidden" name="_template" value="table" />
        <TextField
          type="text"
          name="name"
          error={false}
          id="filled-basic" //filled-error-helper-text
          helperText="" //Incorrect entry.
          label="Nom"
          variant="filled"
          sx={{ width: "90%", margin: ".8em auto" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          type="text"
          name="email"
          error={false}
          id="filled-basic" //filled-error-helper-text
          helperText="" //Incorrect entry.
          label="Email"
          variant="filled"
          sx={{ width: "90%", margin: ".8em auto" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          type="text"
          name="message"
          id="filled-textarea"
          label="Votre message"
          placeholder=""
          multiline
          rows={4}
          variant="filled"
          sx={{ width: "90%", margin: ".8em auto" }}
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
        <Box
          sx={{
            width: "90%",
            margin: ".8em auto",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            endIcon={<RestartAltIcon />}
            sx={{ color: "white" }}
            onClick={() => {
              setName("");
              setEmail("");
              setMsg("");
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="success"
            endIcon={<SendIcon />}
          >
            Envoyer
          </Button>
        </Box>
      </Stack>
    </Card>
  );
};

export default Formulaire;
