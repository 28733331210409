import { useTheme, Stack, useMediaQuery } from "@mui/material";
import { MainStyled } from "./style";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "../../common/mainComponents/about";
import Social from "../../common/mainComponents/social";
import Slide from "../../common/mainComponents/slide";
import { motion, AnimatePresence } from "framer-motion";

const MainComponent = ({ activeLink, setActiveLink }) => {
  const theme = useTheme();
  const isScreenSizeOver900 = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <MainStyled height={isScreenSizeOver900 ? "85%" : "fit-content"}>
      <Stack
        component="section"
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        sx={{
          width: "100%",
          height: { xs: "fit-content", md: "97%" },
          marginTop: ".7em",
        }}
      >
        <Slide activeLink={activeLink} setActiveLink={setActiveLink} />
        <Stack
          spacing={2}
          sx={{
            width: { xs: "100%", md: "35%" },
            height: "100%",
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ x: 700, opacity: 0, width: "100%", height: "85%" }}
              animate={{
                x: 0,
                opacity: 1,
                width: "100%",
                height: "85%",

                transition: { delay: 0.5 },
              }}
              exit={{ x: 700, opacity: 0, width: "100%", height: "85%" }}
            >
              <About
                bgColor={theme.palette.firstBox.main}
                imgOn={true}
                width="100%"
                activeLink={activeLink}
                setActiveLink={setActiveLink}
              />
            </motion.div>
          </AnimatePresence>
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ y: 200, opacity: 0, height: "100%" }}
              animate={{
                y: 0,
                opacity: 1,
                height: "15%",
                transition: { delay: 0.8 },
              }}
              exit={{ y: 200, opacity: 0, height: "100%" }}
            >
              <Social />
            </motion.div>
          </AnimatePresence>
        </Stack>
      </Stack>
    </MainStyled>
  );
};

export default MainComponent;
