import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import SellIcon from "@mui/icons-material/Sell";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import { NavLink } from "react-router-dom";
const pages = [
  { label: "Comptes", path: "compte", icon: <PersonIcon /> },
  { label: "Publicité", path: "publicite", icon: <SellIcon /> },
  { label: "Ajouter", path: "add", icon: <AddIcon /> },
];
const MainListItems = () => {
  const theme = useTheme()
  return (
    <Stack component="nav">
      {pages.map((item, i) => (
        <ListItemButton key={i}>
          <ListItemIcon sx={{color:theme.palette.primary.main}} >{item.icon}</ListItemIcon>
          <NavLink
            to={item.path}
            style={{
              cursor: "pointer",
              fontSize: ".8em",
            }}
          >
            <ListItemText primary={item.label} />
          </NavLink>
        </ListItemButton>
      ))}
      <Divider />
    </Stack>
  );
};

export default MainListItems;
