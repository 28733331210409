import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Toolbar, Container, useTheme } from "@mui/material";
import { Outlet } from "react-router";
import { useLocation } from "react-router";
import AppNav from "../common/adminComponents/appNav";
import AdminDrawer from "../common/adminComponents/drawer";

const AdminComponent = ({ setDarkMode, darkMode }) => {
  const [open, setOpen] = useState(true);
  const [display, setDisplay] = useState(true);
  const theme = useTheme();
  const location = useLocation();
  const commonPaths = ["/admin"];

  useEffect(() => {
    if (commonPaths.includes(location.pathname)) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [location, commonPaths]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <AppNav
        open={open}
        setOpen={setOpen}
        setDarkMode={setDarkMode}
        darkMode={darkMode}
      />
      <AdminDrawer open={open} setOpen={setOpen} />
      <Box
        component="main"
        sx={{
          backgroundColor: theme.palette.background.paper,
          flexGrow: 1,
          height: "100%",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {display ? <h1>admin</h1> : <Outlet />}
        </Container>
      </Box>
    </Box>
  );
};

export default AdminComponent;
