import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const AdminList = () => {
  const theme = useTheme();
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor:  theme.palette.background.default,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const users = [
    {
      user_name: "tom jedusor",
      id: "38983558144",
      role: "THE HALF-BLOOD PRINCE",
    },
    {
      user_name: "nazim",
      id: "93783558144",
      role: "Admin",
    },
    {
      user_name: "louiza",
      id: "2897246688",
      role: "Admin",
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nom d'utilisateur</StyledTableCell>
            <StyledTableCell align="right">Id</StyledTableCell>
            <StyledTableCell align="right">Role</StyledTableCell>
            <StyledTableCell align="right">Modifier</StyledTableCell>
            <StyledTableCell align="right">Supprimer</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell component="th" scope="row">
                {user.user_name}
              </StyledTableCell>
              <StyledTableCell align="right">{user.id}</StyledTableCell>
              <StyledTableCell align="right">{user.role}</StyledTableCell>
              <StyledTableCell align="right">
                <ModeEditIcon />
              </StyledTableCell>
              <StyledTableCell align="right">
                <DeleteForeverIcon />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default AdminList;
